import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import NotFound from './pages/NotFound';
import particles from './components/particles';

const loader = document.querySelector(".preloader");
document.getElementById('body').style.overflow='hidden';
window.addEventListener('load', (event) => {
  loader.classList.remove("preloader");
  loader.classList.add("loader-hide");
  document.getElementById('body').style.overflow='auto';
});

const App = () => {
  return (
    <BrowserRouter >
      <Routes>
        <Route path='*' element={<Home/>} />
        <Route path='/' element={<NotFound/>} />
      </Routes>
    </BrowserRouter>
  );
};

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);


                          //navigation scroll
                          
// const conteneurmenu = document.querySelector('.conteneurmenu')
window.onscroll = function () {


  if (document.documentElement.scrollTop > 80) {
    document.querySelector('.conteneurmenu').style.background = "#000080";
  }

  else {
    document.querySelector('.conteneurmenu').style.background = "transparent";  
  }


};


  window.addEventListener("DOMContentLoaded", function () {
    ReactDOM.render(
      <particles.Particles_2 />, 
      document.getElementById("particles_loading")
  );
  const button = document.querySelector(".hamburger__toggle");
  button.addEventListener( "click", 
                                  function (){
                                    button.classList.toggle("toggled");
                                    if(button.classList.contains("toggled")){
                                      document.querySelector('.nav-menu-mob').style.width="300px";
                                    }
                                    else{
                                      document.querySelector('.nav-menu-mob').style.width="0";
                                    }
                                  }
                                  );

  const langages= document.getElementById('langages') ;
  langages.addEventListener("click", function(){
    const href_fr="https://xchange.bryocorp.com/fr/";
    const href_en="https://xchange.bryocorp.com/en/";
    const actual_href=window.location.href;
    if(langages.checked){
      if(actual_href !== href_fr){
        window.location.href = href_fr;
      }
    }else{
      if(actual_href !== href_en){
        window.location.href = href_en;
      }
    }
  });
});


 


window.addEventListener('scroll', function () {
  
  var scrollValue = document.body.scrollTop || document.documentElement.scrollTop;
  var show_anim_offset = window.scrollY + document.getElementById('show-anim').getBoundingClientRect().top;
  if (scrollValue >= show_anim_offset-600) {
    const element = document.querySelector('.bg-anim');
    element.style.setProperty('--transform-a', "translateY(-100%)");
    element.style.setProperty('--transform-b', "translateY(100%)");
  }
})

export default App;