import React from 'react';
import { NavLink } from 'react-router-dom';
import Checkbox from '../components/checkbox';
const Navigation = () => {
  return (
    <div>
      <div className='conteneurmenu' >
        <NavLink className="conteneurlogo" to='/'>
          <div className='logo aos-item'
            data-aos="fade-right"
            data-aos-duration="1000"
            data-aos-deplay="500"
          ></div>
        </NavLink>
        <button className='hamburger__toggle d-block d-lg-none'>
          <span className='hamburger__icon'></span>
        </button>
        <div className='d-flex align-items-center h-100'>
          <div className='navigation aos-item'
            data-aos="fade-left"
            data-aos-deplay="500"
            data-aos-duration="1000"
          >
            <a className='anim-item-nav from-left' href='#system'>
              Ecosystem 
            </a>
            <a className='anim-item-nav from-left font-weight-bold' href='#service'>Nos services</a>
            <a className='anim-item-nav from-left font-weight-bold' href='#bcoin'>Bcoin token</a>
            <a className='anim-item-nav from-left font-weight-bold' href='#contact'>Contact</a>
            <div className='position-relative mid-parent'>
              <div className="mid">
                <label className="rocker rocker-small">
                  <Checkbox />
                  <span className="switch-left">FR</span>
                  <span className="switch-right">EN</span>
                </label>
              </div>
            </div>
            
          </div>
        </div>
      </div>
    </div>
      
  );
};

export default Navigation;