import React from 'react';
import Navigation from '../components/Navigation';
import Checkbox from '../components/checkbox';
import particles from '../components/particles';
const Home = () => {
  return (
    <div className='container-fuild'>
            <Navigation />
      <div className='nav-menu-mob d-block d-lg-none'>
        <ul>
          <div className="mid">
            <label className="rocker rocker-small">
              <Checkbox />
              <span className="switch-left">FR</span>
              <span className="switch-right">EN</span>
            </label>
          </div>
          <li>
            <a className='anim-item-nav from-left' href='#system'>
              ECOSYSTEME 
            </a>
          </li>
          <li>
            <a className='anim-item-nav from-left' href='#service'>Nos services</a>
          </li>
          <li>
            <a className='anim-item-nav from-left' href='#bcoin'>Bcoin token</a>
          </li>
          <li>
            <a className='anim-item-nav from-left' href='#contact'>Contact</a>
          </li>
          <hr/>
          <div className="link-container text-center">
                <a className="lien mx-3" target='_blank' href='https://twitter.com/XchangeBryo' > 
                  <img src='img/twetter.svg' alt='twitter-icon' className='footimg1'  />
                </a>
                <a className="lien mx-3" target='_blank' href='https://github.com/BRYOXchangeCoin' >
                  <img src='img/github.svg' alt='github-icon' className='footimg2' />
                </a>
                <a className="lien mx-3" target='_blank' href='https://www.instagram.com/p/CZgeodKNJ97/?utm_medium=copy_link' >
                  <img src='img/instagram.svg' alt='instagram-icon' className='footimg3' />
                </a>
                <br/>
                <a className="lien" target='_blank' href='https://t.me/BRYOXchangeDiscussion' >
                <img src='img/telegram.svg' alt='telegram-icon' className='footimg7' />
                </a>
                <br/>
                <a className="lien mx-3" target='_blank' href='https://www.youtube.com/channel/UCYnSuWM36_bvp8dUH6Uaqrg/featured' >
                <img src='img/yt.svg' alt='youtube-icon' className='footimg4'/>
                </a>
                <a className="lien mx-3" target='_blank' href='https://web.facebook.com/bryoxchange2/?ref=page_internal' >
                <img src='img/fb.svg' alt='facebook-icon' className='footimg5'/>
                </a>
                <a className="lien mx-3" href='#' >
                <img src='img/linkedin.svg' alt='linkedin-icon' className='footimg6'/>
                </a>
              </div>
        </ul>
      </div>
      <div>
        <video src="img/World - 1992.mp4" muted autoPlay={"autoplay"} preload="auto" loop>video tag is not supported by your browser</video>
      </div>
      <div className='banner-text' 
        data-aos="fade-up"
        data-aos-duration="1000">
        <h1 className='titre1'>
          BRYO Xchange
        </h1>
        <h3 className='titre2'>
          Nous vous facilitons l’accès aux cryptomonnaies et plus encore...
        </h3>
      </div>
      
      <div className='container-fluid p-0'>
        <div className='position-relative'>
          <particles.Particles_1 />
          <article id='system' className='px-4 pb-5'>
            <div className='row'>
              <div className='col-lg-9 order-last order-lg-first'>
              <div className='row m-0 p-0 d-flex d-lg-none'>
                  <div className='col-12 m-0 p-0'>
                    <div className='rectangle1 py-4 mx-auto d-flex align-items-center' id='rectangle1'>
                    <div className='d-block w-100'>
                      <div className='d-flex align-items-center justify-content-between'>
                        <div className='rec-associate-bar'></div>
                        <div className='rectangle2 me-2'>
                          <p>
                            Difficultés d’accès aux moyens d’échanges.
                          </p>
                        </div>
                        <div className='rectangle2 ms-2'>
                          <p>
                          Diffucultés d’accès à la bonne éducation,flexible.
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                      <div className='d-flex my-3 align-items-center justify-content-between'>
                        <div className='rec-associate-bar'></div>
                        <div className='rectangle2 me-2'>
                          <p>
                          Id local non conforme au KYC international.
                          </p>
                        </div>
                        <div className='rectangle2 ms-2'>
                          <p>
                          Difficultés d’accès au capital risque pour les start-up
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                      <div className='d-flex mb-3 align-items-center justify-content-between'>
                        <div className='rec-associate-bar'></div>
                        <div className='rectangle2 me-2'>
                          <p>
                          Difficultés de monétisation
              des savoir-faire liés aux 
              business du 21 ème siècle 
                          </p>
                        </div>
                        <div className='rectangle2 ms-2'>
                          <p>
                          Comment interagir
              avec la blockchain,
              les smarts contrats... 
              sans savoir coder
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='rectangle2 w-50'>
                          <p>
                          Comment utiliser
              les cryptomonnaies 
              dans la vie réelle
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div className='col-12 d-flex justify-content-center align-items-center m-0 p-0'>
                    <div className='rec-bar'></div>
                    <div className='rec-img'></div>
                  </div>
                  <div className='col-12 m-0 my-auto p-0'>
                    <div className='rectangle3 d-flex mx-auto align-items-center justify-content-center'>
                      <div className='d-block'>
                        <div className='d-flex align-items-center'>
                          <div className='rec3-div'>
                            <p>
                            Acheter, vendre, échanger
                  les cryptomonnaies plus
                  facilement
                            </p>
                          </div>
                          <div className='rec-associate-bar'></div>
                          <div className='vertical-rec-bar'></div>
                          <div className='rec-associate-bar'></div>
                          <div className='rec3-div'>
                            <p>
                            Accéder aux meilleures
                  informations du domaine
                  pour votre éducation
                            </p>
                          </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                          <div className='rec3-div'>
                            <p>
                            Financer plus facilement
                  vos projets grâce aux financements participatifs
                            </p>
                          </div>
                          <div className='rec-associate-bar'></div>
                          <div className='vertical-rec-bar'></div>
                          <div className='rec-associate-bar'></div>
                          <div className='rec3-div'>
                            <p>
                            Professionaliser vos
                  activités
                            </p>
                          </div>
                        </div>
                        <div className='d-flex align-items-center justify-content-center'>
                          <div className='rec3-div'>
                            <p>
                            Intégrer la blockchain à
                  vos activités
                            </p>
                          </div>
                          <div className='rec-associate-bar'></div>
                          <div className='vertical-rec-bar'></div>
                          <div className='rec-associate-bar'></div>
                          <div className='rec3-div'>
                            <p>
                            Acheter en magasin avec 
                  vos cryptomonnaies
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className='row m-0 p-0 d-none d-lg-flex'>
                  <div className='col-lg-4 m-0 p-0'>
                    <div className='rectangle1 py-4 ms-auto d-flex align-items-center' id='rectangle1'>
                    <div className='d-block w-100'>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='rectangle2'>
                          <p className='p1'>
                            Difficultés d’accès aux moyens d’échanges.
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='rectangle2'>
                          <p className='p2'>
                          Diffucultés d’accès à la bonne éducation,flexible.
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='rectangle2'>
                          <p className='p3'>
                          Id local non conforme au KYC international.
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='rectangle2'>
                          <p className='p4'>
                          Difficultés d’accès au capital risque pour les start-up
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='rectangle2'>
                          <p className='p5'>
                          Difficultés de monétisation
              des savoir-faire liés aux 
              business du 21 ème siècle 
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='rectangle2'>
                          <p className='p6'>
                          Comment interagir
              avec la blockchain,
              les smarts contrats... 
              sans savoir coder
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                      <div className='d-flex align-items-center justify-content-end'>
                        <div className='rectangle2'>
                          <p className='p7'>
                          Comment utiliser
              les cryptomonnaies 
              dans la vie réelle
                          </p>
                        </div>
                        <div className='rec-associate-bar'></div>
                      </div>
                    </div>
                    </div>
                  </div>
                  <div className='col-lg-4 m-0 p-0'>
                    <div className='rec-bar'></div>
                    <div className='rec-img'></div>
                  </div>
                  <div className='col-lg-4 m-0 my-auto p-0'>
                    <div className='rectanglegauche py-5 d-flex align-items-center'>
                      <div className='d-block'>
                        <div className='d-flex align-items-center'>
                          <div className='rec-associate-bar'></div>
                          <div className='position-relative'>
                            <p className='achetez'>
                            Acheter, vendre, échanger
                  les cryptomonnaies plus
                  facilement
                            </p>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='rec-associate-bar'></div>
                          <div className='position-relative'>
                            <p className='acceder'>
                            Acceder aux meilleures
                  informations du domaine
                  pour votre education
                            </p>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='rec-associate-bar'></div>
                          <div className='position-relative'>
                            <p className='financer'>
                            Financer plus facilement
                  vos projets grace aux financements participatifs
                            </p>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='rec-associate-bar'></div>
                          <div className='position-relative'>
                            <p className='professionalisez'>
                            Professionaliser vos
                  activités
                            </p>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='rec-associate-bar'></div>
                          <div className='position-relative'>
                            <p className='integrez'>
                            Intégrer la blockchain à
                  vos activités
                            </p>
                          </div>
                        </div>
                        <div className='d-flex align-items-center'>
                          <div className='rec-associate-bar'></div>
                          <div className='position-relative'>
                            <p className='achetez1'>
                            Acheter en magasin avec 
                  vos cryptomonnaies
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div data-aos="fade-down-left" data-aos-duration="1000" data-aos-delay="500" className='col-lg-3 mb-5 mb-lg-0 order-first order-lg-last d-flex align-items-center justify-content-center'>
                <div className='d-block text-center text-lg-start'>
                  <h3 className='titre5'>Un nouveau monde<br/>à votre portée </h3>
                  <p className='pt2'>
                  L’écosystème qui vous facilite l’interaction, qui propulse la finance décentralisée et qui accélère l’adoption des <br/> cryptomonnaies.
                  </p>
                </div>
              </div>
            </div>
          </article>
          <article id='service' className='px-4 py-5'>
            <div className='row py-5'>
              <div data-aos="fade-up-right" data-aos-duration="1000" data-aos-delay="500" className='col-lg-4 mb-5 mb-lg-0 d-flex align-items-center justify-content-center'>
                <div className='d-block text-center text-lg-start'>  
                  <h2 className='titre5'>Découvrez <br/>l’ensemble de nos services</h2>
                  <p className="pt2">Pour atteindre nos objectifs, nous mettons à votre disposition plusieurs outils, facile d’accès, sécurisés et entièrement décentralisés.</p>
                </div>
              </div>
              <div className='col-lg-8 d-none d-lg-block position-relative'>
                <div className='animate-offer-1'></div> 
                <div className='animate-offer-2'></div> 
                <div className='d-flex align-items-center justify-content-center'> 
                  <div className='offer offer-down mx-auto'>
                      <img src='img/BLEAN.svg' alt='blean' className='imgs' /> <br/> 
                    <p className='para'>
                      <strong >Blearn</strong> <br/>
                    Faites vous former et formez d’autres
                    personnes avec des cours en ligne de
                    qualités de façon décentralisée et
                    sécurisée.
                    </p>
                    <div className='offer-div text-end'>
                      <a href='https://xchange.bryocorp.com/fr/learn/' target='_blank' className='shadow-sm rounded-circle'>
                        <i className='fa fa-angle-double-right'></i>
                      </a>
                    </div>
                  </div>
                  <div className='offer mx-auto'>
                    <img src='img/BFINANCE.svg' alt='bank' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BFinance</strong> <br />
                      Un outil de financements participatifs et de la finance désentralisée.
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                  <div className='offer offer-down mx-auto'>
                      <img src='img/bx_favicon.svg' alt='xchange' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BXchange V1</strong> <br/>
                      Achetez, vendez et échangez facilement les cryptos avec
                      du Fiat via mobile money. 
                    </p>
                    <div className='offer-div text-end'>
                      <a href="https://xchange.bryocorp.com/client/" target='_blank' className='shadow-sm rounded-circle'>
                        <i className='fa fa-angle-double-right'></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='d-flex my-5 align-items-center justify-content-center'>
                  <div className='offer mx-auto'>
                      <img src='img/BTERMINAL.svg' alt='code' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BTerminal</strong> <br/>Un outil de création et de gestion de cryptos
        et d’NFTs.
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='offer offer-up mx-auto'>
                      <img src='img/BXV2.svg' alt='blean' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BXchange V2</strong> <br/>Trade + NFTs MarketPlace
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                  <div className='offer mx-auto'>
                      <img src='img/BPAY.svg' alt='blean' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >Bpay</strong> <br/>Un outil de payment qui vous permet 
        d’utiliser directement vos cryptos en
        magasin sans les convertir.
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                  <div className='offer offer-up mx-auto'>
                    <img src='img/BAPP.svg' alt='blean' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BApp</strong> <br/>Notre application mobile.
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-12 d-block d-lg-none'>
                <div className='d-flex align-items-center justify-content-evenly'>  
                  <div className='offer mx-auto'>
                      <img src='img/bx_favicon.svg' alt='xchange' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BXchange V1</strong> <br/>
                      Achetez, vendez et échangez facilement les cryptos avec
                      du Fiat via mobile money. 
                    </p>
                    <div className='offer-div text-end'>
                      <a href="https://xchange.bryocorp.com/client/" target='_blank' className='shadow-sm rounded-circle'>
                        <i className='fa fa-angle-double-right'></i>
                      </a>
                    </div>
                  </div>
                  <div className='offer mx-auto'>
                      <img src='img/BLEAN.svg' alt='blean' className='imgs' /> <br/> 
                    <p className='para'>
                      <strong >Blearn</strong> <br/>
                    Faites vous former et formez d’autres
                    personnes avec des cours en ligne de
                    qualités de façon décentralisée et
                    sécurisée.
                    </p>
                    <div className='offer-div text-end'>
                      <a href='https://xchange.bryocorp.com/fr/learn/' target='_blank' className='shadow-sm rounded-circle'>
                      <i className='fa fa-angle-double-right'></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='d-flex my-4 align-items-center justify-content-evenly'>
                  <div className='offer mx-auto'>
                    <img src='img/BFINANCE.svg' alt='bank' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BFinance</strong> <br />
                      Un outil de financements participatifs et de la finance désentralisée.
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                  <div className='offer mx-auto'>
                      <img src='img/BPAY.svg' alt='blean' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >Bpay</strong> <br/>Un outil de payment qui vous permet 
        d’utiliser directement vos cryptos en
        magasin sans les convertir.
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='d-flex mb-4 align-items-center justify-content-evenly'>
                  <div className='offer mx-auto'>
                      <img src='img/BXV2.svg' alt='blean' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BXchange V2</strong> <br/>Trade + NFTs MarketPlace
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                  <div className='offer mx-auto'>
                      <img src='img/BTERMINAL.svg' alt='code' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BTerminal</strong> <br/>Un outil de création et de gestion de cryptos
        et d’NFTs.
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                </div>
                <div className='d-flex align-items-center justify-content-center'>
                  <div className='offer mx-auto'>
                    <img src='img/BAPP.svg' alt='blean' className='imgs' /> <br/>
                    <p className='para'>
                      <strong >BApp</strong> <br/>Notre application mobile.
                    </p>
                    <div className='offer-div text-end'>
                      <a href="#" onClick={e => e.preventDefault()} className='shadow-sm rounded-circle offer-lock'>
                        <i className='fa fa-lock'></i>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article className='px-4 pb-5' id='bcoin'>
            <div data-aos="500" className='article3textcenter mb-5'>
              <h2 className='titre5 text-center'>Bcoin Token</h2>
              <p className='pt2 text-center'>Les membres de la communauté BRYO Xchange <br/>
                peuvent participer et investir dans l'écosystème BRYO Xchange<br/>
                avec notre jeton multi-utilitaire BCOIN émis sur la blockchain Bsc.</p>
              <div className="button-container-1 my-4">
                <a href='https://xchange.bryocorp.com/fr/coin/' target='_blank'>
                  <span className="mas">Decouvrez Bcoin Token</span>
                  <button id='work' type="button" name="Hover">Decouvrez Bcoin Token</button>
                </a>
              </div>
            </div>
            <div data-aos="zoom-in" className='d-none d-lg-block position-relative bg_test'>
              <div className='bg-anim'></div>
              <div className='d-flex justify-content-center align-items-center'>
                <div className='bt bt-down mx-auto'>
                  <img className='image' src='img/JETON DE PAYEMENT.svg' alt='icones' /> <br/>
                  <strong className='str'> 
                  Jeton de
                  Paiement
                  </strong>
                  <p className='paragraphe'>
                  Utilisez Bcoin pour
      payer vos factures et
      bien d’autres.
                  </p>
                </div>
                <div className='bt mx-auto'>
                  <img className='image' src='img/INDICE DE COMMUNAUTE.svg' alt='icones' /> <br/>
                  <strong className='str'> 
                  Indice communautaire
                  </strong>
                  <p className='paragraphe'>
                  Construisez une communauté durable sur
      le long terme tout en finançant des projets
      identifiés et en donnant votre avis.
                  </p>
                </div> 
                <div className='bt bt-down mx-auto'>
                  <img className='image' src='img/STAKING.svg' alt='icones' /> <br/>
                  <strong className='str'> 
                    Staking
                  </strong>
                  <p className='paragraphe'>
                  Stakez du BCOIN pour
      gagner des revenus passifs
      et plus encore.
                  </p> 
                </div>
              </div>
              <div className='d-flex my-5 justify-content-center align-items-center'>
                <div className='bt-img-1'></div> 
              </div>
              <div className='d-flex justify-content-center align-items-center' id="show-anim">
                <div className='bt bt-top mx-auto'>
                  <img className='image' src='img/BCOIN SOON.svg' alt='icones'  /> <br/>
                  <strong className='str'> 
                    Bcoin Soon
                  </strong>
                  <p className='paragraphe'>
                  D’autres utilités en cours
      de conception pour le bien
      des Bcoin Holders
                  </p>
                </div>
                <div className='bt mx-auto'>
                  <img className='image' src='img/PARTICIPATION.svg' alt='icones' /> <br/>
                  <strong className='str'> 
      Participation
                  </strong>
                  <p className='paragraphe'>
                  Participez aux prises 
      de décisions pour assurer
      l’avenir de l’écosystème. 
                  </p>
                </div> 
                <div className='bt bt-top mx-auto'>
                  <img className='image' src='img/AVENTAGE PREMIUM.svg' alt='icones' /> <br/>
                  <strong className='str'> 
      Avantage premium 
                  </strong>
                  <p className='paragraphe'>
                  Payez jusqu’à 0frais sur les transactions
      et obtenez plusieurs autres avantages.
                  </p>
                </div>
              </div>
            </div>
            <div className='d-block d-lg-none'>
              <div className='bt ms-auto pb-4 text-center'>
                <img className='image' src='img/STAKING.svg' alt='icones' /> <br/>
                <strong className='str'> 
                  Staking
                </strong>
                <p className='paragraphe'>
                Stakez du BCOIN pour
    gagner des revenus passifs
    et plus encore.
                </p> 
              </div>
              <div className='bt-bar-right'>
                <div className='bt-triangle-up'></div>
              </div>
              <div className='bt-bar'>
                <div className='bt-triangle-right'></div>
              </div>
              <div className='bt-bar-left'></div>
              <div className='bt me-auto text-center'>
                <img className='image' src='img/JETON DE PAYEMENT.svg' alt='icones' /> <br/>
                <strong className='str'> 
                Jeton de
                Paiement
                </strong>
                <p className='paragraphe'>
                Utilisez Bcoin pour
    payer vos factures et
    bien d’autres.
                </p>
              </div>
              <div className='bt-bar-left'></div>
              <div className='bt-bar'>
                <div className='bt-triangle-left'></div>
              </div>
              <div className='bt-bar-right'></div>
              <div className='bt pb-4 ms-auto text-center'>
                <img className='image' src='img/PARTICIPATION.svg' alt='icones' /> <br/>
                <strong className='str'> 
    Participation
                </strong>
                <p className='paragraphe'>
                Participez aux prises 
    de décisions pour assurer
    l’avenir de l’écosystème. 
                </p>
              </div> 
              <div className='position-relative'>
                <div className='bt-bar-right'>
                  <div className='bt-triangle-up'></div>
                </div>
                <div className='bt-bar'></div>
                <div className='bt-bar-left'>
                  <div className='bt-triangle-bottom'></div>
                </div>
                <div className='img-block mx-auto'>
                  <div className='bt-img'></div> 
                </div>
              </div>
              <div className='bt pt-4 me-auto text-center'>
                <img className='image' src='img/INDICE DE COMMUNAUTE.svg' alt='icones' /> <br/>
                <strong className='str'> 
                Indice communautaire
                </strong>
                <p className='paragraphe'>
                Construisez une communauté durable sur
    le long terme tout en finançant des projets
    identifiés et en donnant votre avis.
                </p>
              </div> 
              <div className='bt-bar-left'></div>
              <div className='bt-bar'>
                <div className='bt-triangle-right'></div>
              </div>
              <div className='bt-bar-right'></div>
              <div className='bt ms-auto text-center'>
                <img className='image' src='img/AVENTAGE PREMIUM.svg' alt='icones' /> <br/>
                <strong className='str'> 
    Avantage premium 
                </strong>
                <p className='paragraphe'>
                Payez jusqu’à 0frais sur les transactions
    et obtenez plusieurs autres avantages.
                </p>
              </div>
              <div className='bt-bar-right'></div>
              <div className='bt-bar'>
                <div className='bt-triangle-left'></div>
              </div>
              <div className='bt-bar-left'>
                <div className='bt-triangle-bottom'></div>
              </div>
              <div className='bt pt-4 me-auto text-center'>
                <img className='image' src='img/BCOIN SOON.svg' alt='icones'  /> <br/>
                <strong className='str'> 
                  Bcoin Soon
                </strong>
                <p className='paragraphe'>
                D’autres utilités en cours
    de conception pour le bien
    des Bcoin Holders
                </p>
              </div>
            </div>
          </article>
        </div>
        
        <footer id='contact' className='m-0 p-4'>
          <div className='row'>
            <div className='col-6 col-lg-2 mb-4 mb-lg-0  mx-auto'>
              <img className='imgfoot' src='img/avec-texte.svg' alt='' />
              <div className='d-lg-none'>Nous vous facilitons 
    l’accès aux cryptomonnaies 
    et plus encore...</div>
              <div className='d-none d-lg-block foot1 pt-2'>
                <a href='https://xchange.bryocorp.com/fr/coin/#acheter' target='_blank'>
                <p>Wallets</p>
                </a>
                <a href='https://xchange.bryocorp.com/fr/coin/#acheter' target='_blank'>
                <p>Exchanges</p>
                </a>
                <a href='#'>
                <p>Docs</p>
                </a>
                <a href='https://xchange.bryocorp.com/fr/coin/#route' target='_blank'>
                <p>Feuille de route</p>
                </a>
              </div>
            </div>
            <div className='d-block d-lg-none mb-4 mb-lg-0 col-6 text-end pt-2'>
                <a href='https://xchange.bryocorp.com/fr/coin/#acheter'>
                <p>Wallets</p>
                </a>
                <a href='https://xchange.bryocorp.com/fr/coin/#acheter'>
                <p>Exchanges</p>
                </a>
                <a href='#'>
                <p>Docs</p>
                </a>
                <a href='https://xchange.bryocorp.com/fr/coin/#route'>
                <p>Feuille de route</p>
                </a>
              </div>
            <div className='col-6 order-3 mb-4 mb-lg-0 order-lg-1 col-lg-2 pt-2 mx-auto'>
              <div className='foot2'>
                <strong>Construire</strong>  
                <div className='margetop'>
                <a href='#'>
                <p>Outils utiles </p>
                </a>
                <a href='#'>
                <p >Créer un token </p>
                </a>
                <a href='#'>
                <p>Créer un nft </p>
                </a>
                </div>
              </div>
            </div>
            <div className='col-6 text-end mb-4 mb-lg-0 text-lg-start order-2 order-lg-2 col-lg-2 pt-2 mx-auto'>
              <div className='foot3 '>
                <strong>Appliquer</strong> 
                <div className='margetop'>
                <a href='mailto:admin@xchange.bryocorp.com'>
                <p>Subventions </p>
                </a>
                <a href='mailto:admin@xchange.bryocorp.com'>
                <p>Partenaires</p>
                </a>
                <a href='mailto:admin@xchange.bryocorp.com'>
                <p>Ambassadeurs</p>
                </a>
                <a href='https://bryocorp.com/job/' target='_blank'>
                <p>Carrières</p>
                </a>
                <a href='https://forms.gle/ue3AHdkJpPUyiJkU6' target='_blank'>
                <p>Demande de listing</p>
                </a>
                </div>
              </div>
            </div>
            <div className='col-6 col-lg-2 mb-4 mb-lg-0 order-1 order-lg-3 pt-2 mx-auto'>
              <div className='foot4'>
                <strong>Apprendre</strong> 
                <div className='margetop'>
                <a href='#'>
                <p>Sur nous </p>
                </a>
                <a href='#'>
                <p>Equipe</p>
                </a>
                <a href='https://xchange.bryocorp.com/fr/coin/' target='_blank'>
                <p>Qu'est ce que Bcoin </p>
                </a>
                <a href='#'>
                <p>FAQ</p>
                </a>
                <a href='https://xchange.bryocorp.com/fr/coin/?lvr_blanc' target='_blank'>
                <p>Livre Blanc</p>
                </a>
                </div>
              </div>
            </div>
            <div className='col-6 text-end mb-4 mb-lg-0 order-4 order-lg-4 text-lg-start col-lg-2 pt-2 mx-auto'>
              <div className='foot5 '>
                <strong >Autres</strong> 
                <div className='margetop'>

                <a href='#'>
                <p>Conditions & politiques</p>
                </a>
                <a href='https://support.bryocorp.com/' target='_blank'>
                <p>Support</p>
                </a>
                <a href='#'>
                <p>Blog</p>
                </a>
                </div>
              </div>
            </div>
            <div className='col-12 order-5 text-center order-lg-5 col-lg-2 mx-auto'>
              <strong className="d-lg-none">Rejoindre la communauté</strong>
              <div className="link-container">
                <a className="lien" target='_blank' href='https://twitter.com/XchangeBryo' > 
                  <img src='img/twetter.svg' alt='twitter-icon' className='footimg1'  />
                </a>
                <a className="lien" target='_blank' href='https://github.com/BRYOXchangeCoin' >
                  <img src='img/github.svg' alt='github-icon' className='footimg2' />
                </a>
                <a className="lien" target='_blank' href='https://www.instagram.com/p/CZgeodKNJ97/?utm_medium=copy_link' >
                  <img src='img/instagram.svg' alt='instagram-icon' className='footimg3' />
                </a>
                <a className="lien" target='_blank' href='https://www.youtube.com/channel/UCYnSuWM36_bvp8dUH6Uaqrg/featured' >
                <img src='img/yt.svg' alt='youtube-icon' className='footimg4'/>
                </a>
                <a className="lien" target='_blank' href='https://web.facebook.com/bryoxchange2/?ref=page_internal' >
                <img src='img/fb.svg' alt='facebook-icon' className='footimg5'/>
                </a>
                <a className="lien" href='#' >
                <img src='img/linkedin.svg' alt='linkedin-icon' className='footimg6'/>
                </a>
                <a className="lien" target='_blank' href='https://t.me/BRYOXchangeDiscussion' >
                <img src='img/telegram.svg' alt='telegram-icon' className='footimg7' />
                </a>
              </div>
            </div>
          </div>
          <hr/>
          <p className='text-center py-4'>© BRYO Corp 2022. Tous droits réservés </p>
        </footer>
      </div>
      
    </div>
  );
};

export default Home;
