import React from 'react';
import Navigation from '../components/Navigation';

const NotFound = () => {
  return (
    <div className='pages'>
      
      <Navigation />

      <h1 className='text-center'>Erreur 404</h1>
    </div>
  );
};

export default NotFound;