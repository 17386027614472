import React from 'react';

class Checkbox extends React.Component{
    constructor(props){
        super(props)
        this.state={
            checked:true
        }
        this.handleCheck=this.handleCheck.bind(this)
    }
    
    handleCheck(){
        this.setState({
            checked:!this.state.checked
        })
    }
    
    render(){
        return(
                <input id="langages" type="checkbox" 
                onChange={this.handleCheck}
                defaultChecked={this.state.checked}
                />
        )
    }
}
export default Checkbox;